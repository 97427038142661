.category-container {
    background-color: #eeeeee;
  }
  .category-container .category-content {
    width: 95%;
    margin: 0rem auto;
    padding-block: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .category-container .category-content .category-header {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .category-container .category-content .category-header .category-head {
    color: #3e5569;
  }
  
  .category-container .category-content .category-header .add-category {
    border-radius: 7px;
    border: none;
    padding: 10px 20px;
    background-color: #b11226;
    border-color: #b11226;
    box-shadow: 0 2px 6px #b1122661;
    font-weight: 700;
    font-size: 15px;
    color: white;
  }
  
  .category-container .category-content .category-card {
    border-color: 1px solid black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    /* overflow-x: scroll; */
  }
  .category-container
    .category-content
    .category-card
    .category-card-header
    .category-form-control {
    display: flex;
    align-items: end;
    overflow-x: scroll;
    gap: 2rem;
  }
  .category-container
    .category-content
    .category-card
    .category-card-header
    .category-form-control::-webkit-scrollbar {
    display: none;
  }
  .category-container
    .category-content
    .category-card
    .category-card-header
    .category-form-control
    .category-input {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0.8rem;
  }
  .category-container
    .category-content
    .category-card
    .category-card-header
    .category-form-control
    .category-input
    .category-input-cal {
    border: none;
    border-bottom: 1px solid #ccc;
    background: #fffefe;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
    font-size: 17px;
    font-weight: 400;
    width: 100%;
    width: 250px;
  }
  .category-container
    .category-content
    .category-card
    .category-card-header
    .category-form-control
    .category-input
    .category-input-cal:focus {
    color: #4f5467;
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25);
    outline: 0;
  }
  .category-container
    .category-content
    .category-card
    .category-card-header
    .category-form-control
    .category-input
    .search-input {
    border-radius: 18px;
    display: block;
    width: 100%;
    padding: 6px 10px;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #4f5467;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9ecef;
    width: 250px;
  }
  .category-container
    .category-content
    .category-card
    .category-card-header
    .category-form-control
    .category-input
    .search-input:focus {
    color: #4f5467;
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25);
    outline: 0;
  }
  .category-container
    .category-content
    .category-card
    .category-card-header
    .category-form-control
    .category-form-button {
    border-radius: 22px;
    margin-right: 4px;
    padding-block: 6px;
    padding-left: 13px;
    padding-right: 13px;
    font-size: 14px;
    background-color: #b11226;
    color: white;
    border: none;
    cursor: pointer;
  }
  .category-container
    .category-content
    .category-card
    .category-table
    .table-image {
    height: 90px;
    width: 90px;
    object-fit: cover;
  }
  .category-container
    .category-content
    .category-card
    .category-table
    .table-image
    img {
    height: 100%;
    width: 100%;
  }
  
  .category-container .category-content .category-card .category-table .badge {
    min-width: 100px;
    min-height: 40px;
    padding: 10px 15px;
    border-radius: 2px;
    text-align: center;
  }
  .category-container
    .category-content
    .category-card
    .category-table
    .badge-success {
    color: #fff;
    background-color: #28a745;
    font-size: 14px;
    font-weight: 600 !important;
  }
  .category-container
    .category-content
    .category-card
    .category-table
    .action-cell {
    display: flex;
    gap: 1.4rem;
    align-items: center;
  }
  .category-container
    .category-content
    .category-card
    .category-table
    .action-cell
    .edit-cell {
    background-color: #b11226;
    color: white;
    padding: 0.375rem 0.75rem;
    box-shadow: 0 2px 6px #acb5f6;
    border-radius: 2px;
  }
  
  .category-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .category-table th,
  .category-table td {
    padding: 10px;
    text-align: left;
  }
  
  .category-table th {
    border-bottom: 1px solid #ccc;
    color: rgba(0, 0, 0, 0.54);
  }
  
  .category-table td {
    border-bottom: 1px solid #ccc;
  }
  
  .add-category-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
  }
  
  .add-category-modal-overlay .add-category-modal-content-header {
    height: 3.5rem;
    background-color: #b11226;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .add-category-modal-overlay .add-category-modal-content {
    background: #fff;
    border-radius: 8px;
    position: relative;
  }
  
  .add-category-modal-overlay .add-category-close-button {
    position: absolute;
    top: 10px;
    right: 14px;
    font-size: 24px;
    cursor: pointer;
    color: white;
    background-color: #b11226;
  }
  
  .add-category-modal-overlay .add-category-modal-form {
    width: auto;
    margin: 2rem auto;
  }
  .add-category-modal-overlay .add-category-popup-footer {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
  }
  .add-category-modal-overlay .add-category-popup-footer .cancel-button {
    padding: 9px 20px;
    font-weight: 500;
    border-radius: 7px;
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    margin: 0px 10px;
    border: none;
    cursor: pointer;
  }
  .add-category-modal-overlay .add-category-popup-footer .submit-button {
    background-color: #b11226;
    border-color: #b11226;
    box-shadow: 0 2px 6px #b1122661;
    border-radius: 7px;
    padding: 9px 20px;
    font-weight: 500;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .edit-category-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
  }
  
  .edit-category-modal-overlay .edit-category-modal-content-header {
    height: 3.5rem;
    background-color: #b11226;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .edit-category-modal-overlay .edit-category-modal-content {
    background: #fff;
    border-radius: 8px;
    position: relative;
  }
  
  .edit-category-modal-overlay .edit-category-close-button {
    position: absolute;
    top: 10px;
    right: 14px;
    font-size: 24px;
    cursor: pointer;
    color: white;
    background-color: #b11226;
  }
  
  .edit-category-modal-overlay .edit-category-modal-form {
    width: auto;
    margin: 2rem auto;
  }
  .edit-category-modal-overlay .edit-category-popup-footer {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
  }
  .edit-category-modal-overlay .edit-category-popup-footer .cancel-button {
    padding: 9px 20px;
    font-weight: 500;
    border-radius: 7px;
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    margin: 0px 10px;
    border: none;
    cursor: pointer;
  }
  .edit-category-modal-overlay .edit-category-popup-footer .submit-button {
    background-color: #b11226;
    border-color: #b11226;
    box-shadow: 0 2px 6px #b1122661;
    border-radius: 7px;
    padding: 9px 20px;
    font-weight: 500;
    border: none;
    color: white;
    cursor: pointer;
  }