.approve-approve-container {
  background-color: #eeeeee;
}
.approve-approve-container .approve-approve-content {
  width: 95%;
  margin: 0rem auto;
  padding-block: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.approve-approve-container .approve-approve-content .approve-approve-header {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.approve-approve-container
  .approve-approve-content
  .approve-approve-header
  .approve-approve-head {
  color: #3e5569;
  font-weight: bold;
}

.approve-approve-container
  .approve-approve-content
  .approve-approve-header
  .add-approve-approve {
  border-radius: 7px;
  border: none;
  padding: 10px 20px;
  background-color: #b11226;
  border-color: #b11226;
  box-shadow: 0 2px 6px #b1122661;
  font-weight: 700;
  font-size: 15px;
  color: white;
}

.approve-approve-container .approve-approve-content .approve-approve-card {
  border-color: 1px solid black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* margin-top: 2rem; */
  padding: 15px 10px;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-card-header
  .approve-approve-form-control {
  display: flex;
  align-items: end;
  overflow-x: scroll;
  gap: 3rem;
  /* position: fixed; */
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-card-header
  .approve-approve-form-control::-webkit-scrollbar {
  display: none;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-card-header
  .approve-approve-form-control
  .approve-approve-input {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.8rem;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-card-header
  .approve-approve-form-control
  .approve-approve-input
  .approve-approve-input-cal {
  border: none;
  border-bottom: 1px solid #ccc;
  background: #fffefe;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  font-size: 17px;
  font-weight: 400;
  width: 180px;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-card-header
  .approve-approve-form-control
  .approve-approve-input
  .approve-approve-input-cal:focus {
  color: #4f5467;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-card-header
  .approve-approve-form-control
  .approve-approve-input
  .search-input {
    border: none;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #4f5467;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 1px solid #e9ecef;
  width: 180px;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-card-header
  .approve-approve-form-control
  .approve-approve-input
  .search-input:focus {
  color: #4f5467;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-card-header
  .approve-approve-form-control
  .approve-approve-form-button {
    border-radius: 5px;
    margin-right: 4px;
    padding-block: 6px;
    font-size: 20px;
    font-weight: bold;
    background-color: #b11226;
    color: white;
    border: none;
    cursor: pointer;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-card-header
  .approve-approve-form-control
  .approve-approve-form-excel {
  border-radius: 5px;
  margin-right: 4px;
  padding-block: 6px;
  font-size: 20px;
  font-weight: bold;
  background-color: #358053;
  color: white;
  border: none;
  cursor: pointer;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-table {
  overflow-y: auto;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-table
  .table-image {
  height: 40px;
  width: 40px;
  object-fit: cover;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-table
  .table-image
  img {
  height: 100%;
  width: 100%;
}

.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-table
  .badge {
  min-width: 100px;
  min-height: 40px;
  padding: 10px 15px;
  border-radius: 2px;
  text-align: center;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-table
  .badge-success {
  color: #fff;
  background-color: #28a745;
  font-size: 12px;
  font-weight: 600 !important;
}

.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-table
  .action-cell {
  display: flex;
  gap: 1.4rem;
  align-items: center;
}
.approve-approve-container
  .approve-approve-content
  .approve-approve-card
  .approve-approve-table
  .action-cell
  .edit-cell {
  background-color: #b11226;
  color: white;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 2px 6px #acb5f6;
  border-radius: 2px;
}

.approve-approve-table table {
  margin-top: 3rem;
  width: 100%;
  border-collapse: collapse;
}

.approve-approve-table th,
.approve-approve-table td {
  padding: 10px;
  text-align: left;
}

.approve-approve-table .th1 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 60px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th1 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 60px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th1 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 60px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th1 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 60px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th2 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 140px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th3 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 150px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th4 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 150px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th5 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 160px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th6 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 60px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th7 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 120px;
  font-weight: bold;
  font-size: 4px;
}
.approve-approve-table .th8 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 160px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th9 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 130px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th10 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 60px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th11 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 60px;
  font-weight: bold;
  font-size: 14px;
}
.approve-approve-table .th12 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 160px;
  font-weight: bold;
  font-size: 14px;
}


.approve-approve-table td {
  border-bottom: 1px solid #ccc;
  color: black;
  font-size: 14px;
}

.add-approve-approve-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.add-approve-approve-modal-overlay .add-approve-approve-modal-content-header {
  height: 3.5rem;
  background-color: #b11226;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.add-approve-approve-modal-overlay .add-approve-approve-modal-content {
  background: #fff;
  border-radius: 8px;
  position: relative;
}

.add-approve-approve-modal-overlay .add-approve-approve-close-button {
  position: absolute;
  top: 10px;
  right: 14px;
  font-size: 24px;
  cursor: pointer;
  color: white;
  background-color: #b11226;
}

.add-approve-approve-modal-overlay .add-approve-approve-modal-form {
  width: auto;
  margin: 2rem auto;
}
.add-approve-approve-modal-overlay .add-approve-approve-popup-footer {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.add-approve-approve-modal-overlay
  .add-approve-approve-popup-footer
  .cancel-button {
  padding: 9px 20px;
  font-weight: 500;
  border-radius: 7px;
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  margin: 0px 10px;
  border: none;
  cursor: pointer;
}
.add-approve-approve-modal-overlay
  .add-approve-approve-popup-footer
  .submit-button {
  background-color: #b11226;
  border-color: #b11226;
  box-shadow: 0 2px 6px #b1122661;
  border-radius: 7px;
  padding: 9px 20px;
  font-weight: 500;
  border: none;
  color: white;
  cursor: pointer;
}

.edit-category-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.edit-category-modal-overlay .edit-category-modal-content-header {
  height: 3.5rem;
  background-color: #b11226;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.edit-category-modal-overlay .edit-category-modal-content {
  background: #fff;
  border-radius: 8px;
  position: relative;
}

.edit-category-modal-overlay .edit-category-close-button {
  position: absolute;
  top: 10px;
  right: 14px;
  font-size: 24px;
  cursor: pointer;
  color: white;
  background-color: #b11226;
}

.edit-category-modal-overlay .edit-category-modal-form {
  width: auto;
  margin: 2rem auto;
}
.edit-category-modal-overlay .edit-category-popup-footer {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.edit-category-modal-overlay .edit-category-popup-footer .cancel-button {
  padding: 9px 20px;
  font-weight: 500;
  border-radius: 7px;
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  margin: 0px 10px;
  border: none;
  cursor: pointer;
}
.edit-category-modal-overlay .edit-category-popup-footer .submit-button {
  background-color: #b11226;
  border-color: #b11226;
  box-shadow: 0 2px 6px #b1122661;
  border-radius: 7px;
  padding: 9px 20px;
  font-weight: 500;
  border: none;
  color: white;
  cursor: pointer;
}
