.boxes-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
  }
  
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 100px;
    margin: 10px;
    border-radius: 10px;
    color: white;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .number {
    font-size: 24px;
    font-weight: bold;
  }
  
  .text {
    font-size: 16px;
    font-weight: bold;
  }
  
  .green-gradient {
    background: linear-gradient(135deg, #38ef7d, #11998e);
  }
  
  .purple-gradient {
    background: linear-gradient(135deg, #9d50bb, #6e48aa);
  }
  
  .blue-gradient {
    background: linear-gradient(135deg, #56ccf2, #2f80ed);
  }
  
  .orange-gradient {
    background: linear-gradient(135deg, #f2994a, #f2c94c);
  }
  