.login-container {
  /* width: 500px;
    position: absolute;
    top: 20%;
    left: 30%; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.highlighted {
  color: #18e909 !important;
  /* Text color when highlighted */
}
.password-input-container {
  display: flex;
  align-items: center; /* Align items vertically */
  width: 100%;
}

.password-toggle-icon {
  margin-left: 5px; /* Add some space between the input and the icon */
}


.login-container .login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 20px;
  text-align: center;
  max-width: 600px;
  width: 100%;
  background-color: #fff;
  position: relative;
}
.login-container .login-content .login-content-header h1 {
  color: #b11226;
  margin-bottom: 1rem;
}

.login-container .login-content .login-form-container {
  margin-top: 1rem;
}
.login-container .login-content .login-form-container {
  display: flex;
  gap: 1rem;
}
.login-container .login-content .login-form-container .form-control {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 500px;
}
.login-container .login-content .login-form-container .form-control label {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #4f4f4f;
}
.login-container .login-content .login-form-container .login-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}
.login-container
  .login-content
  .login-form-container
  .login-input
  .login-input-email {
  padding: 15px 7px;
  border: none;
  border-bottom: 1px solid #ccc;
  background: #fffefe;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  font-size: 17px;
  font-weight: 600;
  width: 100%;
}
.login-container
  .login-content
  .login-form-container
  .login-input
  .login-input-email:focus {
  color: #4f5467;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
}

.login-container
  .login-content
  .login-form-container
  .login-forgot
  .forgot-btn {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #b11226;
  float: right;
  margin-bottom: 1rem;
}
.login-container .login-content .login-form-container .login-submit-button {
  width: 100%;
  background: #b11226;
  border-radius: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  padding: 16px;
  border-color: #b11226;
  border: none;
  box-shadow: 0 2px 6px #b11226a8;
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  .login-container .login-content .login-form-container .form-control {
    width: 350px;
  }
}
/* Custom scrollbar styles */
